exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-properties-index-js": () => import("./../../../src/pages/commercial-properties/index.js" /* webpackChunkName: "component---src-pages-commercial-properties-index-js" */),
  "component---src-pages-new-developments-index-js": () => import("./../../../src/pages/new-developments/index.js" /* webpackChunkName: "component---src-pages-new-developments-index-js" */),
  "component---src-pages-off-plan-properties-index-js": () => import("./../../../src/pages/off-plan/properties/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-index-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-templates-area-guide-details-js": () => import("./../../../src/templates/area-guide-details.js" /* webpackChunkName: "component---src-templates-area-guide-details-js" */),
  "component---src-templates-areaguide-landing-template-js": () => import("./../../../src/templates/areaguide-landing-template.js" /* webpackChunkName: "component---src-templates-areaguide-landing-template-js" */),
  "component---src-templates-campaign-template-js": () => import("./../../../src/templates/campaign-template.js" /* webpackChunkName: "component---src-templates-campaign-template-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/career-details.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-career-landing-template-js": () => import("./../../../src/templates/career-landing-template.js" /* webpackChunkName: "component---src-templates-career-landing-template-js" */),
  "component---src-templates-client-reviews-landing-template-js": () => import("./../../../src/templates/client-reviews-landing-template.js" /* webpackChunkName: "component---src-templates-client-reviews-landing-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-developer-details-template-js": () => import("./../../../src/templates/developerDetailsTemplate.js" /* webpackChunkName: "component---src-templates-developer-details-template-js" */),
  "component---src-templates-developerlanding-template-js": () => import("./../../../src/templates/developerlandingTemplate.js" /* webpackChunkName: "component---src-templates-developerlanding-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-form-template-js": () => import("./../../../src/templates/form-template.js" /* webpackChunkName: "component---src-templates-form-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-market-report-details-js": () => import("./../../../src/templates/market-report-details.js" /* webpackChunkName: "component---src-templates-market-report-details-js" */),
  "component---src-templates-market-reports-landing-template-js": () => import("./../../../src/templates/market-reports-landing-template.js" /* webpackChunkName: "component---src-templates-market-reports-landing-template-js" */),
  "component---src-templates-new-development-details-js": () => import("./../../../src/templates/new-development-details.js" /* webpackChunkName: "component---src-templates-new-development-details-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-news-landing-template-js": () => import("./../../../src/templates/news-landing-template.js" /* webpackChunkName: "component---src-templates-news-landing-template-js" */),
  "component---src-templates-offplan-landing-template-js": () => import("./../../../src/templates/offplan-landing-template.js" /* webpackChunkName: "component---src-templates-offplan-landing-template-js" */),
  "component---src-templates-ourstory-landing-template-js": () => import("./../../../src/templates/ourstory-landing-template.js" /* webpackChunkName: "component---src-templates-ourstory-landing-template-js" */),
  "component---src-templates-primary-landing-template-js": () => import("./../../../src/templates/primary-landing-template.js" /* webpackChunkName: "component---src-templates-primary-landing-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-static-template-js": () => import("./../../../src/templates/static-template.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */),
  "component---src-templates-team-landing-template-js": () => import("./../../../src/templates/team-landing-template.js" /* webpackChunkName: "component---src-templates-team-landing-template-js" */),
  "component---src-templates-thankyou-template-js": () => import("./../../../src/templates/thankyou-template.js" /* webpackChunkName: "component---src-templates-thankyou-template-js" */),
  "component---src-templates-valuation-template-js": () => import("./../../../src/templates/valuation-template.js" /* webpackChunkName: "component---src-templates-valuation-template-js" */)
}

